"use strict";

function loginForm() {
    var csrfToken = document.head.querySelector("[name~=csrf-token][content]").content;

    return {
        message: null,
        loading: false,
        formData: {
            username: '',
            password: '',
        },

        submitData() {
            this.loading = true;
            this.message = null;

            fetch('/login', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": csrfToken
                },
                credentials: "same-origin",
                body: JSON.stringify(this.formData),
            })
            .then(response => response.json())
            .then((data) => {
                if (data.error && data.error.status_code === 422) {
                    this.message = data.error.message;;
                    return;
                }

                window.location = data.redirect_path;
            })
            .catch((error) => {
                this.message = 'Der skete en fejl, prøv igen'
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}